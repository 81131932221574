<template>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <div slot="emptystate" class="text-center">
        {{ $t('Global.Emptystate') }}
      </div>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'" class="action">
            <span
              v-b-tooltip.hover.top="
                $t(
                  `Global.${
                    $can($acl.action.Update, $acl.subjects.Processes)
                      ? 'EditSubject'
                      : 'ViewSubject'
                  }`,
                  { subject: $t('Management.Processes.Process') },
                )
              "
            >
          <feather-icon
            :icon="
              $can($acl.action.Update, $acl.subjects.Processes)
                ? 'Edit2Icon'
                : 'EyeIcon'
            "
            size="16"
            class="hover-green"
            @click="edit(props.row.id)"
          />
        </span>
        <span v-if="props.row.processType === 'VA'">
          <PrintSeal
          :id="props.row.processId"
          :showText="false"
          iconSize="16"
          :process="props.row"
          ></PrintSeal>
        </span>

        </span>
        <!-- <span v-else-if="props.column.field === 'areas'">
          {{ getAreaFromRow(props.formattedRow) }}
        </span> -->
        <!-- Column: Common -->
        <span v-else-if="props.column.field === 'stock'">
          {{ weightFormatter.format(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>
  
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
          <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
            <span class="text-nowrap">
              {{ $t('Global.ShowingOneOf') }}
            </span>
            <v-select
              v-model="pageLength"
              class="mx-1"
              style="min-width: 75px"
              :clearable="false"
              :options="['10', '15', '30']"
              @input="value => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </template>
  
  <script>
  import { BPagination } from 'bootstrap-vue';
  import { VueGoodTable } from 'vue-good-table';
  import vSelect from 'vue-select';
  import PrintSeal from '../../certs-and-docs/seal/printSeal.vue';
  import { SortTypesEnum } from '@/data/enums';
  import { weightFormatter } from '@/utility/format/formatter';

  export default {
    components: {
      VueGoodTable,
      vSelect,
      BPagination,
      PrintSeal,
    },
    props: {
      rows: {
        type: Array,
        default: () => [],
      },
      searchTerm: {
        type: String,
        default: () => '',
      },
    },
    watch: {
      rows: function(newValue) {
          if(this.rows){
            this.rows.forEach(row => {
            row.area = this.getAreaFromRow(row);
          });
        }
      }
    },
  
    data() {
      return {
        pageLength: 10,
        isLoading: false,
        totalRecords: 0,
        serverParams: {
          columnFilters: {
          },
          sort: [
            {
              field: '',
              type: ''
            }
          ],
          page: 1, 
          perPage: 10,
          processType: '',
          harvestYear: '',
          global: '',
          rawHop: 0
        },
        SortTypesEnum,
        weightFormatter,
        columns: [
          {
            label: this.$t('Management.Processes.ProcessId'),
            field: 'processId',
          },
          {
            label: this.$t('Management.Processes.ProcessType'),
            field: 'processType',
          },
          {
            label: this.$t('Management.Processes.HarvestYear'),
            field: 'harvestYear',
            type: 'number'
          },
          {
            label: this.$t('Management.Processes.Stock'),
            field: 'stock',
            type: 'number'
          },
          {
            label: this.$t('Management.Processes.Shareholder'),
            field: 'shareholder',
          },
          {
            label: this.$t('Management.Processes.Country'),
            field: 'area',
          },
          {
            label: this.$t('Global.Action'),
            field: 'action',
            sortable: false,
          },
        ],
      }
    },

    created() {
      this.load();
    },

    methods:{

      async load() {
        const { data } = await this.$axios.get("processes/process/count");
        this.totalRecords = data;
      },
        edit(id) {
            this.$router.push({
            name: 'seals/processes-update',
            params: { id },
            })
        },

        getAreaFromRow(row){
          let retVal = "";
          let areas = [];
          for (let index = 0; index < row.areas.length; index++) {
            const area = row.areas[index];
            if(areas.findIndex(a => a === area.country.name) === -1){
              areas.push(area.country.name);
            }
          }

          for (let index = 0; index < areas.length; index++) {
            const areaName = areas[index];
            retVal += areaName;
            if(areas[index +1 ]){
              retVal += ", "
            }
          }
          return retVal;
        },

        updateParams(newProps) {
          this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        
        onPageChange(params) {
          this.updateParams({page: params.currentPage});
          this.loadItems();
        },

        onPerPageChange(params) {
          this.updateParams({perPage: Number(params.currentPerPage)});
          this.loadItems();
        },

        onSortChange(params) {
          console.log(params);
          console.log(params[0]);
          this.updateParams({
            sort: [{
              field: params[0].field,
              type: params[0].type,
            }],
          });
          this.loadItems();
        },
        
        onColumnFilter(params) {
          this.updateParams(params);
          this.loadItems();
        },

        // load items is what brings back the rows from server
        loadItems() {
          this.$emit('load', this.serverParams)
          // getFromServer(this.serverParams).then(response => {
          //   this.totalRecords = response.totalRecords;
          //   this.rows = response.rows;
          // });
        }
    },
  }
  </script>
